<template>
  <div v-if="totalPage > 1" class="w-max flex items-center gap-3">
    <button
        @click="changePage(+currentPage - 1)"
        class="w-10 h-10 text-primary hover:text-primary duration-400 cursor-pointer flex justify-center items-center"
        :class="currentPage <= 1 ? 'opacity-20 pointer-events-none' : ''"
    >
      <span class="i-custom-chevron-right tranform rotate-180 w-6 h-6"></span>
    </button>

    <div class="flex gap-1 items-center">
      <ul class="flex">
        <template v-for="page in totalPage">
          <li>
            <button
                @click="changePage(page)"
                class="duration-400 flex justify-center items-center rounded-full h-10 w-10 itemPagination text-base font-semibold text-bw-17 cursor-pointer"
                :class="page == currentPage ? 'bg-primary !text-white' : ' hover:bg-neutral-06'"
                v-if="currentPage + 2 >= page && currentPage - 2 <= page"
            >
              {{ page }}
            </button>
          </li>
        </template>
      </ul>
    </div>
    <button
        @click="changePage(+currentPage + 1)"
        class="w-10 h-10 text-primary hover:text-primary duration-400 cursor-pointer flex justify-center items-center"
        :class="currentPage >= totalPage ? 'opacity-20 cursor-not-allowed pointer-events-none' : ''"
    >
      <span class="i-custom-chevron-right w-6 h-6"></span>
    </button>
  </div>
</template>

<script setup lang="ts">
defineProps({
  totalPage: {
    type: Number
  },
  currentPage: {
    type: Number
  }
})

const changePage = async (page: Number) => {
  if (useRoute().query.page) {
    const queryNew = {}
    for (const key in useRoute().query) {
      const value = useRoute().query[key]
      if (key == 'page') {
        queryNew[key] = page
      } else {
        queryNew[key] = value
      }
    }
    await useRouter().push({
      path: useRoute().path,
      query: {
        ...queryNew
      }
    })
  } else {
    let queryNew = {
      ...useRoute().query,
      page: page
    }
    await useRouter().push({
      path: useRoute().fullPath,
      query: {
        ...queryNew
      }
    })
  }
}
</script>
